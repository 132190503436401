import {connect} from "react-redux";
import {Dialog, FlatButton} from "material-ui";
import React from "react";
import {doAuthRedirection} from "actions/auth";
import {CLOSE_SESSION_EXPIRY_DIALOG} from "../constants/ActionTypes";
import {SESSION_GRACE_DIALOG_TIME, SESSION_GRACE_REFRESH_TIME} from "../constants/session";


class SessionExpiryDialog extends React.Component {

  state = {
    timer: SESSION_GRACE_DIALOG_TIME
  };

  componentDidMount() {
    this.myInterval = setInterval(() => {
        if (this.state.timer !== 0) {
          this.setState({timer: this.state.timer - 1})
        } else {
          // token expired refresh if remember me is checked it should refresh if not it will go back to login page
          this.props.doAuthRedirection();
        }
      }, 1000
    )

  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  render() {
    console.log('timer', this.state.timer);

    const {timer} = this.state;
    const {
      displaySessionExpirationDialog,
      doAuthRedirection,
    } = this.props;

    return (

      <Dialog
        title="Your session is about to expire"
        actions={[

          <FlatButton
            label="Ok"
            primary
            onClick={() => this.props.closeDialog(timer)}
          />,
        ]}
        modal={false}
        open={displaySessionExpirationDialog}
        onRequestClose={() => this.props.closeDialog(timer)}
      >
        <p>Click OK to refresh your session<br/>
          Expires in: {this.state.timer} seconds</p>
      </Dialog>
    )
  }
}


const mapStateToProps = (state, ownProps) => ({
  displaySessionExpirationDialog: state.session.displaySessionExpirationDialog

});

const mapDispatchToProps = (dispatch) => ({
  doAuthRedirection: () => {
    dispatch(doAuthRedirection());
  },
  closeDialog: (time) => {
    if (time <= SESSION_GRACE_REFRESH_TIME) { // handles the case if dialog was closed at 1 second remaining.
      setTimeout(() => dispatch(doAuthRedirection()), (time - 1) * 1000);
    } else {
      setTimeout(() => dispatch(doAuthRedirection()), (time - SESSION_GRACE_REFRESH_TIME) * 1000); //set timeout for auth redirection
    }
    dispatch({type: CLOSE_SESSION_EXPIRY_DIALOG});
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SessionExpiryDialog);


import React from 'react';
import {render} from 'react-dom';
import {applyMiddleware, compose, createStore} from 'redux';
import {Provider} from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import {createLogger} from 'redux-logger';

import reducers from './reducers';
import App from './containers/App';
import {tryAuth} from './actions/auth';

const loggerMiddleware = createLogger();

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(
    thunkMiddleware,
    loggerMiddleware
  ))
);


//Start authentication

store.dispatch(tryAuth({}, window.location.hash))

render(
  <Provider store={store}>
    <App/>
  </Provider>,
  document.getElementById('app-container')
);

export const TOGGLE_BOXED_LAYOUT = 'TOGGLE_BOXED_LAYOUT';
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSED_NAV';
export const TOGGLE_NAV_BEHIND = 'TOGGLE_NAV_BEHIND';
export const TOGGLE_FIXED_HEADER = 'TOGGLE_FIXED_HEADER';
export const CHANGE_SIDEBAR_WIDTH = 'CHANGE_SIDEBAR_WIDTH';
export const CHANGE_COLOR_OPTION = 'CHANGE_COLOR_OPTION';
export const CHANGE_THEME = 'CHANGE_THEME';


export const OPEN_APP = 'OPEN_APP';
export const DOWNLOAD = 'DOWNLOAD';
export const NAVIGATE_TO_VERSION = 'NAVIGATE_TO_VERSION';

//UI related
export const SNACK_BAR_REQUEST_CLOSE = 'SNACK_BAR_REQUEST_CLOSE';
export const SNACK_BAR_REQUEST_OPEN = 'SNACK_BAR_REQUEST_OPEN';

export const NOTIFY_BAR_REQUEST_CLOSE = 'NOTIFY_BAR_REQUEST_CLOSE';
export const NOTIFY_BAR_REQUEST_OPEN = 'NOTIFY_BAR_REQUEST_OPEN';
export const UPDATE_LAST_UPDATE_TIME = 'UPDATE_LAST_UPDATE_TIME';
export const FAILED_TO_FETCH_UPTIME = 'FAILED_TO_FETCH_UPTIME';

// Session related
export const SESSION_READY = 'SESSION_READY';
export const CLEAR_SESSION = 'CLEAR_SESSION';
export const ORG_READY = 'ORG_READY';
export const OPEN_SESSION_EXPIRY_DIALOG = 'OPEN_SESSION_EXPIRY_DIALOG';
export const CLOSE_SESSION_EXPIRY_DIALOG = 'CLOSE_SESSION_EXPIRY_DIALOG';
export const OPEN_CONTACT_US_DIALOG = 'OPEN_CONTACT_US_DIALOG';
export const CLOSE_CONTACT_US_DIALOG = 'CLOSE_CONTACT_US_DIALOG';


//Release related
//version list is fetched from server
export const VERSIONS_READY = 'VERSIONS_READY';
export const ALL_VERSIONS_READY = 'ALL_VERSIONS_READY';
export const START_FETCHING = 'START_FETCHING';
export const INCIDENTS_READY = 'INCIDENTS_READY';
export const UPTIME_STATS_READY = 'UPTIME_STATS_READY';

//set version to display
export const SET_VERSION_TO_DISPLAY = 'SET_VERSION_TO_DISPLAY';

//set version to display
export const VERSION_FILE_INFO_READY = 'VERSION_FILE_INFO_READY';

export const SENDING_ENQUIRY = 'SENDING_ENQUIRY';
export const SENT_ENQUIRY = 'SENT_ENQUIRY';


//profile related
export const UPDATE_PROFILE_BASIC_INFO = 'UPDATE_PROFILE_BASIC_INFO';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_ORG_SUCCESS = 'UPDATE_ORG_SUCCESS';
export const FETCHING_SUBSCRIPTIONS = 'FETCHING_SUBSCRIPTIONS';
export const SUBSCRIPTIONS_READY = 'SUBSCRIPTIONS_READY';
export const SUBSCRIPTION_CHANGE = 'SUBSCRIPTION_CHANGE';
export const SUBSCRIBE_START = 'SUBSCRIBE_START';
export const SUBSCRIBE_SUCCESSFUL = 'SUBSCRIBE_SUCCESSFUL';
export const SUBSCRIBE_FAILED = 'SUBSCRIBE_FAILED';

//email verification related
export const EMAIL_VERIFICATION_SENT = 'EMAIL_VERIFICATION_SENT';
export const EMAIL_INVITATION_CONFIRMATION = 'EMAIL_INVITATION_CONFIRMATION';
export const EMAIL_VERIFICATION_CONFIRMED = 'EMAIL_VERIFICATION_CONFIRMED';
export const UPDATE_IS_VERIFIED_SUCCESS = 'UPDATE_IS_VERIFIED_SUCCESS';

// invitation related
export const OPEN_INVITE_DIALOG = 'OPEN_INVITE_DIALOG';
export const CLOSE_INVITE_DIALOG = 'CLOSE_INVITE_DIALOG';
export const INVITATION_SENT = 'INVITATION_SENT';
export const INVITATION_CONFIRMATION = 'INVITATION_CONFIRMATION';
export const INVITATION_CONFIRMED = 'INVITATION_CONFIRMED';
export const CLOSE_INVITATION_CONFIRMATION_DIALOG = 'CLOSE_INVITATION_CONFIRMATION_DIALOG';
export const INVITATIONS_READY = 'INVITATIONS_READY';
export const OPEN_USER_DELETE_DIALOG = 'OPEN_USER_DELETE_DIALOG';
export const CLOSE_USER_DELETE_DIALOG = 'CLOSE_USER_DELETE_DIALOG';
export const OPEN_USER_MANAGE_DIALOG = 'OPEN_USER_MANAGE_DIALOG';
export const CLOSE_USER_MANAGE_DIALOG = 'CLOSE_USER_MANAGE_DIALOG';
export const CHECK_INVITATION = 'CHECK_INVITATION';

//License related
export const SERVER_REGISTERED = 'SERVER_REGISTERED';
export const SERVER_DELETED = 'SERVER_DELETED';
export const SERVERS_READY = 'SERVERS_READY';
export const SELECT_3DS_SERVER = 'SELECT_3DS_SERVER';
export const SERVER_URL_TEST_STARTED = 'SERVER_TEST_STARTED';
export const SERVER_URL_TEST_DONE = 'SERVER_TEST_DONE';
export const SERVER_PLAN_PURCHASE = 'SERVER_PLAN_PURCHASE';
export const PAK_READY = 'PAK_READY';
export const DNS_READY = 'DNS_READY';
export const ACTIVATION_STATE_READY = 'ACTIVATION_STATE_READY';

// usage related
export const USAGE_TYPE_CHANGED = 'USAGE_TYPE_CHANGED';
export const USAGE_STATS_READY = 'USAGE_STATS_READY';
export const USAGE_START_DATE_CHANGED = 'USAGE_START_DATE_CHANGED ';
export const USAGE_END_DATE_CHANGED = 'USAGE_END_DATE_CHANGED ';
export const USAGE_TIME_FORMAT_CHANGED = 'USAGE_TIME_FORMAT_CHANGED';

// server activation related
export const CHOOSE_ACTIVATION_METHOD = 'CHOOSE_ACTIVATION_METHOD';
export const CHOOSE_USAGE_DATA_ELEMENTS = 'CHOOSE_USAGE_DATA_ELEMENTS';
export const ACTIVATION_NEXT_STEP = 'ACTIVATION_NEXT_STEP';
export const ACTIVATION_PREVIOUS_STEP = 'ACTIVATION_PREVIOUS_STEP';
export const ACTIVATION_ID_READY = 'ACTIVATION_ID_READY';
export const SERVER_USAGE_DATA_ELEMENTS_READY = 'SERVER_USAGE_DATA_ELEMENTS_READY';
export const OPEN_ACTIVATION_METHOD_DIALOG = 'OPEN_ACTIVATION_METHOD_DIALOG';
export const CLOSE_ACTIVATION_METHOD_DIALOG = 'CLOSE_ACTIVATION_METHOD_DIALOG';

//SDK related
export const SDK_REGISTERED = 'SDK_REGISTERED';
export const APPS_READY = 'APPS_READY';
export const IOS_LICENSE_READY = 'IOS_LICENSE_READY';
export const ANDROID_LICENSE_READY = 'ANDROID_LICENSE_READY';
export const SELECT_APP = 'SELECT_APP';
export const SDK_DELETED = 'SDK_DELETED';
export const SDK_USAGE_STATS_READY = 'SDK_USAGE_STATS_READY';
export const SDK_VERSIONS_FETCHED = 'SDK_VERSIONS_FETCHED';
export const SELECT_SDK = 'SELECT_SDK';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const SDK_USAGE_DATA_ELEMENTS_READY = 'SDK_USAGE_DATA_ELEMENTS_READY';

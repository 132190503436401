import moment from 'moment';

export const arrayToObject = (array, keyField, sortKey) => {

  if (Array.isArray(array)) {
    array.sort(((a, b) => a[sortKey] > b[sortKey]));
    return array.reduce((obj, item) => {
      obj[item[keyField]] = item;
      return obj;
    }, {});
  }
  return array;
};

export const dateKey = (date) => {
  return moment(date)
    .format('DD/MM/YYYY');
};

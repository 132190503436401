import React from "react";
import SendIcon from "material-ui/svg-icons/content/send";
import {FlatButton} from "material-ui";
import {submitEnquiry} from "../../../../actions/releases";
import QueueAnim from "rc-queue-anim";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useDispatch, useSelector} from "react-redux";
import useForm from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormHelperText from "@material-ui/core/FormHelperText";
import {red} from "@material-ui/core/colors";

const mWidthStyle = {
  minWidth: '135px',
};

const styles = theme => ({
  messageArea: {
    // border: 'solid 1px #979797 !important;',
    padding: '5px !important;'
    // textarea: {
    //   border: 'solid 1px #979797 !important;',
    //   height: '150px !important;',
    //   padding: '5px !important;'
    // }
  },
  formControl: {
    margin: theme.spacing(3),
  },
  submitBtn: {
    borderRadius: '4px !important;',
    backgroundColor: '#e22d58 !important;',
    color: '#fff !important;',
    '&:disabled': {
      backgroundColor: '#bababb !important;',
      svg: {
        //fill: rgba(0, 0, 0, 0.3) !important;
      }
    }
  },
  fieldError: {
    color: red[700],
  }
});

const ContactForm = (props) => {

  const {classes, enquiryTypes} = props;

  const {handleSubmit, register, errors, formState, setValue, setError} = useForm({
    defaultValues: {
      type: "Sales"
    }
  });

  const {sendingEnquiry} = useSelector(state => state.releases);

  const dispatch = useDispatch();

  const onSubmit = values => {
    console.log(values);

    if (!values.type) {
      setError('type', 'required', 'Enquiry type is required');
      return;
    }

    dispatch(submitEnquiry(values, setError))
  };

  const handleChange = (event) => {
    setValue('type', event.target.value);
  };

  return (


    <form onSubmit={handleSubmit(onSubmit)}>

      {sendingEnquiry ?
        <CircularProgress size={50} thickness={5}/>
        :
        <div className="row">

          {enquiryTypes &&
          // Side by side radioButton
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">ENQUIRY TYPE</FormLabel>
            <RadioGroup aria-label="gender" name={"type"} onChange={handleChange}>
              <FormControlLabel value="Sales" control={<Radio inputRef={register}/>} label="Sales"/>
              <FormControlLabel value="Support" control={<Radio inputRef={register}/>} label="Support"/>
              <FormControlLabel value="Other" control={<Radio inputRef={register}/>} label="Other"/>
            </RadioGroup>
            {errors.type && errors.type.type === "required" &&
            <FormHelperText
              className={classes.fieldError}>{errors.type.message}</FormHelperText>}
          </FormControl>

          }


          <TextField
            id="standard-full-width"
            label="Message (Minimum 5 characters)"
            name={"message"}
            rows="5"
            className={classes.messageArea}
            // classes={{textarea: classes.messageArea.textarea}}
            inputRef={register({required: true, minLength: 5})}
            // helperText="Your Message here.."
            fullWidth
            variant="outlined"
            margin="normal"
            multiline={true}
          />
          <FormHelperText
            className={classes.fieldError}>{errors.message && errors.message.type === "required" && 'Message is required.'}
          </FormHelperText>
          <FormHelperText
            className={classes.fieldError}>{errors.message && errors.message.type === "minLength" && 'Must contain at least 5 characters.'}
          </FormHelperText>

          <FlatButton style={mWidthStyle}
                      label="Send"
                      className={classes.submitBtn}
                      secondary
                      icon={<SendIcon style={{fill: "#fff"}}/>}
                      labelPosition="before"
                      type="submit"
                      disabled={!formState.dirty || formState.isSubmitting}
          />
        </div>
      }


      {errors && errors.api &&
      <QueueAnim type="scale" duration={800} className="ui-animate">
        <div key="1" style={{display: 'inline-block'}}>
          <div className="callout callout-danger">
            <h4>Failed to send message</h4>
            <p>{errors.api.message}</p>
          </div>
          <br/>
        </div>
      </QueueAnim>
      }
    </form>
  );
};

export default withStyles(styles)(ContactForm);

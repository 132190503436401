import {
  ALL_VERSIONS_READY,
  FAILED_TO_FETCH_UPTIME,
  INCIDENTS_READY,
  SENDING_ENQUIRY,
  SENT_ENQUIRY,
  SET_VERSION_TO_DISPLAY,
  START_FETCHING,
  UPDATE_LAST_UPDATE_TIME,
  UPTIME_STATS_READY,
  VERSION_FILE_INFO_READY,
  VERSIONS_READY
} from '../constants/ActionTypes';
import {dateKey} from '../utils/utils';
import {DATA_POINTS, INCIDENT_VISIBILITIES} from '../constants/releases';
import moment from 'moment';

const initialSettings = {
  previousVersions: [],
  latestVersion: {},
  otherVersion: {},
  versionToDisplay: {},
  files: new Map(),
  fetched: null,
  sendingEnquiry: false,
  isFetching: false,
  incidents: null,
  uptimeStats: null,
  lastUpdateTime: null,
  uptimeStatsReady: false,
};


const getVersionToDisplay = (versions, version) => {
  return versions == null ? null : versions.find(x => x.version === version);
};

const releases = (state = initialSettings, action) => {
  switch (action.type) {
    case VERSIONS_READY:
      let versions = action.versions;
      let latest;
      let prev;
      let other;

      if (versions == null || versions.length === 0) {
        latest = null;
        prev = null;
        other = null;
      } else {

        // console.log("versions", versions);
        // sort by releaseDate
        versions.sort(function (a, b) {
          if (a.releaseDate > b.releaseDate) {
            return -1;
          }
          if (a.releaseDate < b.releaseDate) {
            return 1;
          }
          return 0;
        });
        // console.log("sorted versions", versions);

        latest = versions.find(v => v.version !== 'other');
        prev = versions.slice(1); //previous version is not used currently.
        other = versions.find(v => v.version === 'other');
      }

      return {
        ...state,
        previousVersions: {
          ...state.previousVersions,
          [action.product]: prev
        },
        otherVersion: {
          ...state.otherVersion,
          [action.product]: other
        },
        versionToDisplay: {
          ...state.versionToDisplay,
          [action.product]: latest
        },
        latestVersion: {
          ...state.latestVersion,
          [action.product]: latest
        },
        fetched: {
          ...state.fetched,
          [action.product]: new Date()
        },
      };

    case ALL_VERSIONS_READY: {

      let latestVersions = {};
      // const otherVersions = {}

      Object.values(action.data)
        .map(data => {

          if (data.version) {
            latest = data.version.find(v => v.version !== 'other');
            // other = data.version.find(v => v.version === 'other');

            // otherVersions[data.product] = other;
            latestVersions[data.product] = latest;
          }
        });


      return {
        ...state,
        latestVersion: latestVersions,
        isFetching: false
        // otherVersion: otherVersions
      };
    }

    case SET_VERSION_TO_DISPLAY:

      return {
        ...state,
        versionToDisplay: {
          ...state.versionToDisplay,
          [action.product]: getVersionToDisplay(state.previousVersions[action.product], action.version),
        }

      };
    case VERSION_FILE_INFO_READY:
      return {
        ...state,
        files: {

          [action.product]: {
            ...state.files[action.product],
            [action.version]: action.data
          }

        },
        isFetching: false
      };

    case SENDING_ENQUIRY: {
      return {
        ...state,
        sendingEnquiry: true
      };
    }

    case SENT_ENQUIRY: {
      return {
        ...state,
        sendingEnquiry: false
      };
    }

    case START_FETCHING: {
      return {
        ...state,
        isFetching: true
      };
    }

    case INCIDENTS_READY: {

      if (!action.data) {
        return {
          ...state,
        };
      }


      // Make the incident type as a key and date as a sub key
      let incidents = {};

      let incidentsByDate = {};

      let ongoingIncidents = {};


      // Remove incidents that are false positive
      action.data.filter(d => !d.falsePositive && d.visibility === INCIDENT_VISIBILITIES.PUBLISHED.value)
        .filter(d => d.dataPoint !== DATA_POINTS.S3.value)
        .forEach(d => {

          if (!incidents[d.dataPoint]) {
            incidents[d.dataPoint] = {};
          }

          const date = dateKey(d.occurredAt);

          if (!incidentsByDate[date]) {
            incidentsByDate[date] = [];
          }

          if (!incidents[d.dataPoint][date]) {
            incidents[d.dataPoint][date] = [];
          }
          incidents[d.dataPoint][date].push(d);
          incidentsByDate[date].push(d);

          if (!d.recoveredAt) {

            if (!ongoingIncidents[d.severity]) {
              ongoingIncidents[d.severity] = [];
            }

            ongoingIncidents[d.severity].push(d);
          }
        });

      console.log('new ongoing incidents', ongoingIncidents);


      return {
        ...state,
        incidents: incidents,
        incidentsByDate: incidentsByDate,
        ongoingIncidents: ongoingIncidents,
      };

    }
    case UPTIME_STATS_READY: {

      let uptimeStats = {};

      action.data.forEach(data => {
        uptimeStats[data.severity] = {
          ...data,
        };
      });

      return {
        ...state,
        uptimeStats: uptimeStats,
        uptimeStatsReady: true,
      };
    }
    case UPDATE_LAST_UPDATE_TIME: {
      return {
        ...state,
        lastUpdateTime: moment()
      };

    }
    case FAILED_TO_FETCH_UPTIME: {
      return {
        ...state,
        uptimeStatsReady: action.ready,
      };
    }
    default:
      return state;
  }
};

export default releases;

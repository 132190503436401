export const timeFormats = ["DAILY", "WEEKLY", "MONTHLY", "YEARLY"];

export const usageTypes = {
  DirectoryServerType: {
    PRODUCTION: {
      name: "Production",
      value: "PRODUCTION"
    },
    GPAYMENTS_TEST_LAB: {
      name: "Test Lab",
      value: "GPAYMENTS_TEST_LAB"
    },
    // TEST: {
    //     name: "Testing",
    // },
    // UNRECOGNIZED: {
    //   name: "Unrecognised",
    // }
  },
  PaymentNetwork: {

    AMERICAN_EXPRESS: {
      name: "American Express",
      value: "AMERICAN_EXPRESS"
    },
    DINERS_CLUB: {
      name: "Diners Club",
      value: "DINERS_CLUB"
    },
    JCB: {
      name: "JCB",
      value: "JCB"
    },
    MASTERCARD: {
      name: "Mastercard",
      value: "MASTERCARD"
    },
    VISA: {
      name: "Visa",
      value: "VISA"
    },

    // UNRECOGNIZED: {
    //   name: "Unrecognised",
    // }
  },
  DeviceChannel: {
    BROWSER: {
      name: "browser",
      value: "BROWSER",
    },
    MOBILE: {
      name: "mobile",
      value: "MOBILE",
    },
    THREE_RI: {
      name: "3RI",
      value: "THREE_RI",
    },
    // UNRECOGNIZED: {
    //   name: "Unrecognised",
    // }
  },
  // transStatus: {
  //   Y: {
  //     name: "Success (Y)"
  //   },
  //   // C: {
  //   //   name: "Challenge (C)"
  //   // },
  //   N: {
  //     name: "Not Authenticated (N)"
  //   },
  //   U: {
  //     name: "Not Performed (U)"
  //   },
  //   R: {
  //     name: "Rejected (R)"
  //   },
  //   A: {
  //     name: "Attempted (A)"
  //   },
  //   // UNRECOGNIZED: {
  //   //   name: "Unrecognised",
  //   // }
  // }

};

export const USAGE_TYPE_DEFS = {
  // AuthenticationType: {
  //   value: "AuthenticationType",
  //   options: usageTypes.AuthenticationType,
  // },
  DirectoryServerType: {
    text: 'Total Authentications',
    value: "DirectoryServerType",
    options: usageTypes.DirectoryServerType,
  },
  PaymentNetwork: {
    text: 'Card Schemes',
    value: "PaymentNetwork",
    options: usageTypes.PaymentNetwork,
  },
  DeviceChannel: {
    text: 'Device Channel',
    value: "DeviceChannel",
    options: usageTypes.DeviceChannel,
  },
  // TransStatus: {
  //   value: "TransStatus",
  //   options: usageTypes.TransStatus
  // }
};

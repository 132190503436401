import React from 'react';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import DropDownMenu from 'material-ui/DropDownMenu'
import FlatButton from 'material-ui/FlatButton';
import IconButton from 'material-ui/IconButton/IconButton';
import {Link, hashHistory} from 'react-router';
import Divider from 'material-ui/Divider';

const HeaderIconButtonStyle = {
  width: '60px',
  height: '60px'
};

const listItemStyle = {
  paddingLeft: '40px' // 36 + 16, algin with sub list
};

class NavLeftList extends React.Component {

  handleChange = (event, value) => {
    hashHistory.push(value);
  }

  render() {
    return (
      <ul className="list-unstyled list-inline">
        {/*<li className="list-inline-item">*/}
        {/*<IconMenu*/}
        {/*iconButtonElement={<IconButton*/}
        {/*style={HeaderIconButtonStyle}*/}
        {/*className="md-button header-btn">*/}
        {/*<i className="material-icons">notifications_none</i>*/}
        {/*<span className="badge">4</span>*/}
        {/*</IconButton>}*/}
        {/*onChange={this.handleChange}*/}
        {/*anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}*/}
        {/*targetOrigin={{horizontal: 'left', vertical: 'top'}}*/}
        {/*menuStyle={{minWidth: '250px'}}*/}
        {/*>*/}
        {/*<MenuItem*/}
        {/*className="header-icon-dropdown-item"*/}
        {/*leftIcon={<i className="material-icons">mail_outline</i>}*/}
        {/*primaryText="New mail from Susan"*/}
        {/*secondaryText={<span className="text-muted">5min ago</span>}*/}
        {/*/>*/}
        {/*<MenuItem*/}
        {/*className="header-icon-dropdown-item"*/}
        {/*leftIcon={<i className="material-icons">mail_outline</i>}*/}
        {/*primaryText="New mail from John"*/}
        {/*secondaryText={<span className="text-muted">1h ago</span>}*/}
        {/*/>*/}
        {/*<Divider/>*/}
        {/*<MenuItem*/}
        {/*className="header-icon-dropdown-item"*/}
        {/*leftIcon={<i className="material-icons">chat_bubble_outline</i>}*/}
        {/*primaryText="Message from Anna"*/}
        {/*secondaryText={<span className="text-muted">5min ago</span>}*/}
        {/*/>*/}
        {/*<MenuItem*/}
        {/*className="header-icon-dropdown-item"*/}
        {/*leftIcon={<i className="material-icons">chat_bubble_outline</i>}*/}
        {/*primaryText="Message from Jane"*/}
        {/*secondaryText={<span className="text-muted">1h ago</span>}*/}
        {/*/>*/}
        {/*<Divider/>*/}
        {/*<MenuItem*/}
        {/*className="header-icon-dropdown-item"*/}
        {/*leftIcon={<i className="material-icons">notifications_none</i>}*/}
        {/*primaryText="Copy task completed"/>*/}
        {/*</IconMenu>*/}
        {/*</li>*/}

        {/*<li className="list-inline-item">*/}

          {/*<DropDownMenu*/}
            {/*value={1}*/}
            {/*// onChange={this.handleChange}*/}
            {/*// style={styles.customWidth}*/}
            {/*autoWidth={true}*/}
          {/*>*/}
            {/*<MenuItem value={1} primaryText="Payment Gateway A"/>*/}
            {/*<Divider/>*/}
            {/*<MenuItem value={2} primaryText="Change My Organization"/>*/}
            {/*<MenuItem value={3} primaryText="Authentication Statistics"/>*/}
            {/*<MenuItem value={4} primaryText="Send Invitation Email"/>*/}
          {/*</DropDownMenu>*/}

          {/*/!*<h5 className="d-inline-block">Payment Gateway A </h5>*!/*/}


        {/*</li>*/}


      </ul>
    );
  }
}

export default NavLeftList;

import {
  ACTIVATION_ID_READY,
  ACTIVATION_NEXT_STEP,
  ACTIVATION_PREVIOUS_STEP,
  ACTIVATION_STATE_READY,
  ANDROID_LICENSE_READY,
  APPS_READY,
  CHANGE_LANGUAGE,
  CHOOSE_ACTIVATION_METHOD,
  CHOOSE_USAGE_DATA_ELEMENTS,
  DNS_READY,
  FETCHING_SUBSCRIPTIONS,
  IOS_LICENSE_READY,
  PAK_READY,
  SDK_USAGE_DATA_ELEMENTS_READY,
  SDK_VERSIONS_FETCHED,
  SELECT_3DS_SERVER,
  SELECT_APP,
  SELECT_SDK,
  SERVER_URL_TEST_DONE,
  SERVER_URL_TEST_STARTED,
  SERVER_USAGE_DATA_ELEMENTS_READY,
  SERVERS_READY,
  SUBSCRIBE_FAILED,
  SUBSCRIBE_START,
  SUBSCRIBE_SUCCESSFUL,
  SUBSCRIPTION_CHANGE,
  SUBSCRIPTIONS_READY,
  USAGE_END_DATE_CHANGED,
  USAGE_START_DATE_CHANGED,
  USAGE_STATS_READY,
  USAGE_TIME_FORMAT_CHANGED,
  USAGE_TYPE_CHANGED
} from '../constants/ActionTypes';
import {timeFormats, USAGE_TYPE_DEFS, usageTypes} from "../constants/licenses";
import CHARTCONFIG from "../constants/ChartConfig";

const date = new Date();

const initialSettings = {
  servers: [],
  currentServer: null,
  usage: {
    startDate: new Date(date.getFullYear(), 0, 1),
    endDate: new Date(date.getFullYear() + 1, 0, 0),
    timeFormat: timeFormats[2],
    type: USAGE_TYPE_DEFS.DirectoryServerType.value
  },
  serverUsageElements: [],
  sdkUsageElements: [],
  currentActivation: {
    activeStep: 0 //contains current active step in activation
  },
  urlTesting: false,
  apps: [],
  currentApp: null,
  sdkLicenses: null,
  // @deprecated
  sdkVersions: [],
  sdkToken: null,
  languageChosen: null,

  // todo: this should be moved to another reducer since its not part of licensing server now.
  subscriptions: {},
  loadingSubscriptions: false,
  isSubscribing: false
};

const license = (state = initialSettings, action) => {

  switch (action.type) {
    case SERVERS_READY:
      return {
        ...state,
        servers: action.data.servers,
        totalPages: action.data.totalPages
      };
    case SELECT_3DS_SERVER:
      return {
        ...state,
        currentServer: action.server,
        currentActivation: {
          activeStep: 0
        }
      };
    case PAK_READY:

      return {
        ...state,
        currentServer: {
          ...state.currentServer,
          pak: action.pak,
          pakFailed: action.pakFailedMessage
        }
      };

    case DNS_READY: {
      return {
        ...state,
        currentServer: {
          ...state.currentServer,
          dns: action.dns,
          dnsFailed: action.dnsFailedMessage
        }
      }
    }

    case CHOOSE_ACTIVATION_METHOD: {
      return {
        ...state,
        currentActivation: {
          ...state.currentActivation,
          activationMethod: action.name
        }
      }
    }

    case CHOOSE_USAGE_DATA_ELEMENTS: {

      const usageDataElements = [];

      Object.keys(action.elements).map(key => {
        // push the names of selected usage data elements.
        if (action.elements[key]) {
          usageDataElements.push(key);
        }
      });

      return {
        ...state,
        currentActivation: {
          ...state.currentActivation,
          usageDataElements: usageDataElements
        }
      }
    }

    case ACTIVATION_NEXT_STEP: {
      return {
        ...state,
        currentActivation: {
          ...state.currentActivation,
          activeStep: state.currentActivation.activeStep + 1
        }
      }
    }

    case ACTIVATION_PREVIOUS_STEP: {
      return {
        ...state,
        currentActivation: {
          ...state.currentActivation,
          activeStep: state.currentActivation.activeStep - 1
        }
      }
    }

    case ACTIVATION_ID_READY: {
      return {
        ...state,
        currentServer: {
          ...state.currentActivation,
          actId: action.id
        }
      }
    }

    case SERVER_USAGE_DATA_ELEMENTS_READY: {
      return {
        ...state,
        serverUsageElements: action.elements
      }
    }

    case SDK_USAGE_DATA_ELEMENTS_READY: {
      return {
        ...state,
        sdkUsageElements: action.elements
      }
    }

    case ACTIVATION_STATE_READY:

      if (action.success) {
        return {
          ...state,
          currentServer: {
            ...state.currentServer,
            actState: action.state
          }
        };
      } else {
        return {
          ...state,
          currentServer: {
            ...state.currentServer,
            actState: 'FAILED'
          }
        };

      }
    case USAGE_STATS_READY:

      const option = {
        title: {
          text: `Usage Stats`
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: [],
          textStyle: {
            color: CHARTCONFIG.color.text
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {show: true, title: 'save'}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
          axisLabel: {
            textStyle: {
              color: CHARTCONFIG.color.text
            }
          },
          splitLine: {
            lineStyle: {
              color: CHARTCONFIG.color.splitLine
            }
          },

        },
        yAxis: {
          type: 'value',
          axisLabel: {
            textStyle: {
              color: CHARTCONFIG.color.text
            }
          },
          splitLine: {
            lineStyle: {
              color: CHARTCONFIG.color.splitLine
            }
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: CHARTCONFIG.color.splitArea
            }
          }
        },
        series: []
      };

      const series = {}; // map with key as usage type value

      Object.values(usageTypes[state.usage.type]).map(serie => {

        option.legend.data.push(serie.name);
        series[serie.value] = {
          name: serie.name,
          data: [],
          type: "line"
        };
      });

      if (action.usage) {
        action.usage.data.forEach(data => {
          if (state.usage.timeFormat === timeFormats[1]) {
            data.date = "WC " + data.date
          }
          // make the x axis
          option.xAxis.data.push(data.date);

          console.log(data);

          // add default values if it does not exist
          Object.values(usageTypes[state.usage.type]).forEach(usageType => {

            let authCount = data.authCounts ? data.authCounts.find(d => d.name === usageType.value) : null;

            if (authCount) {
              series[usageType.value].data.push(authCount.count);
            } else {
              series[usageType.value].data.push(0);
            }
          });
        });
      }

      return {
        ...state,
        usage: {
          ...state.usage,
          option: {
            ...option,
            series: Object.values(series),
          },
        }
      };


    case USAGE_TYPE_CHANGED: {
      return {
        ...state,
        usage: {
          ...state.usage,
          type: action.value
        }
      }
    }

    case USAGE_START_DATE_CHANGED: {

      let endDate = state.usage.endDate;
      // if its set to after the end date make the end date to be the same
      if (action.date > state.usage.endDate) {
        endDate = action.date;
      }

      return {
        ...state,
        usage: {
          ...state.usage,
          startDate: action.date,
          endDate: endDate
        }
      }
    }

    case USAGE_END_DATE_CHANGED: {

      let startDate = state.usage.startDate;
      // if its set to before the start date make the start date to be the same
      if (action.date < state.usage.startDate) {
        startDate = action.date;
      }

      return {
        ...state,
        usage: {
          ...state.usage,
          startDate: startDate,
          endDate: action.date
        }
      }
    }

    case USAGE_TIME_FORMAT_CHANGED: {
      return {
        ...state,
        usage: {
          ...state.usage,
          timeFormat: action.format
        }
      }
    }
    case SERVER_URL_TEST_STARTED:
      return {
        ...state,
        urlTesting: true
      };
    case SERVER_URL_TEST_DONE:
      return {
        ...state,
        urlTesting: false,
        urlTestingResult: action.success,
        urlTestingErrorMessage: action.errorMsg
      };


    case APPS_READY:
      return {
        ...state,
        apps: action.data
      };
    case SELECT_APP:

      return {
        ...state,
        currentApp: action.app,
        sdkLicenses: null,
      };
    case ANDROID_LICENSE_READY:
      return {
        ...state,
        sdkLicenses: {
          ...state.sdkLicenses,
          android: action.licenseKey,
        }
      };

    case IOS_LICENSE_READY: {
      return {
        ...state,
        sdkLicenses: {
          ...state.sdkLicenses,
          ios: action.licenseKey,
        }
      };
    }
    case SDK_VERSIONS_FETCHED:
      return {
        ...state,
        sdkVersions: action.sdkVersions,
        sdkToken: null,
      };
    case SELECT_SDK:
      return {
        ...state,
        sdkToken: action.sdkToken
      };

    case CHANGE_LANGUAGE:
      return {
        ...state,
        languageChosen: action.language,
      };

    case FETCHING_SUBSCRIPTIONS: {
      return {
        ...state,
        loadingSubscriptions: true
      }
    }

    case SUBSCRIBE_START: {
      return {
        ...state,
        isSubscribing: true
      }
    }

    case SUBSCRIBE_SUCCESSFUL:
    case SUBSCRIBE_FAILED: {
      return {
        ...state,
        isSubscribing: false
      }
    }

    case SUBSCRIPTIONS_READY: {

      const interests = {};

      // add the word id_ to key because redux form does not support number `name`
      Object.keys(action.interests).map(key => {
        interests[`id_${key}`] = action.interests[key];
      });

      return {
        ...state,
        subscriptions: interests,
        loadingSubscriptions: false
      }
    }

    case SUBSCRIPTION_CHANGE: {
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [action.id]: action.checked
        }
      }
    }


    default:
      return state;

  }
};

export default license;

export const FULL_ACCESS = 'ff07f0ca-e390-484a-af9f-0f5e760e432e';
export const WRITE_ACCESS = 'ae9cc380-2159-4ee0-851e-db26581b98c3';
export const READ_ACCESS = '9a27037e-d6df-49a4-b31b-620bedec4385';

/**
 * Helper function to check if the user holds certain permission.
 * @param productCode
 * @param accessPermission
 * @param permissions
 * @returns {boolean}
 */
export const checkPermission = (productCode, accessPermission, permissions) => {
  return ((productCode + '_' + accessPermission) in permissions);
};


export const PERMISSIONS = {
  AS_SAAS_READ_INCIDENTS: {
    value: 'as-saas/READ_INCIDENTS',
  },
};

export const ROLES = {
  ROLE_ADMIN: {
    value: 'ROLE_ADMIN',
  }
};


/**
 * Checks that if the user has any of the given permissions.
 * If the user is an ADMIN we always allow the user to pass through.
 * @param user - the user currently in session.
 * @param authorities - the permissions that user should have.
 */
export const hasAnyPermission = (user, authorities) => {

  if (!authorities || authorities.length === 0) {
    return true;
  }

  if (!user || !user.authorities) {
    return false;
  }

  const authorityList = user.authorities.split(',');
  for (const authority of authorityList) {
    if (authorities.includes(authority)) {
      return true;
    }
  }
  return false;
};

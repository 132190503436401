import React from 'react';
// import {Link, hashHistory} from 'react-router';
import {Link, } from 'react-router-dom';


import FlatButton from 'material-ui/FlatButton';
import 'jquery-slimscroll/jquery.slimscroll.min';
import {connect} from 'react-redux';
import history from '../../routes/history';
import {products} from '../../constants/products';
import {checkPermission, FULL_ACCESS, READ_ACCESS} from '../../constants/userAccessPermissions';

class SidebarContent extends React.Component {

  componentDidMount() {
    const nav = this.nav;
    const $nav = $(nav);

    // scroll
    $nav.slimscroll({
      height: '100%'
    });


    // Append icon to submenu
    // Append to child `div`
    $nav.find('.prepend-icon').children('div').prepend('<i class="material-icons">keyboard_arrow_right</i>');


    // AccordionNav
    const slideTime = 250;
    const $lists = $nav.find('ul').parent('li');
    $lists.append('<i class="material-icons icon-has-ul">arrow_drop_down</i>');
    const $As = $lists.children('a');

    // Disable A link that has ul
    $As.on('click', event => event.preventDefault());

    // Accordion nav
    $nav.on('click', (e) => {

      const target = e.target;
      const $parentLi = $(target).closest('li'); // closest, insead of parent, so it still works when click on i icons
      if (!$parentLi.length) return; // return if doesn't click on li
      const $subUl = $parentLi.children('ul');


      // let depth = $subUl.parents().length; // but some li has no sub ul, so...
      const depth = $parentLi.parents().length + 1;

      // filter out all elements (except target) at current depth or greater
      const allAtDepth = $nav.find('ul').filter(function () {
        if ($(this).parents().length >= depth && this !== $subUl.get(0)) {
          return true;
        }
        return false;
      });
      allAtDepth.slideUp(slideTime).closest('li').removeClass('open');

      // Toggle target
      if ($parentLi.has('ul').length) {
        $parentLi.toggleClass('open');
      }
      $subUl.stop().slideToggle(slideTime);

    });


    // HighlightActiveItems
    const $links = $nav.find('a');

    // const currentLocation = history.getCurrentLocation();
    const currentLocation = history.location;
    console.log('navbar: current location', currentLocation);

    function highlightActive(pathname) {
      const path = `${pathname}`;

      $links.each((i, link) => {
        const $link = $(link);
        const $li = $link.parent('li');
        const href = $link.attr('href');

        if ($li.hasClass('active')) {
          $li.removeClass('active');
        }
        if (path.indexOf(href) === 0 && href !== '/') {
          $li.addClass('active');
        }
      });
    }

    highlightActive(currentLocation.pathname);
    history.listen((location) => {
      highlightActive(location.pathname);
    });
  }


  render() {

    const {user} = this.props;
    console.log('user values', user);

    // Show only the profile button if user is not verified or password is not expired
    // Show only the profile button if user is not verified or password is not expired
    if (!user.verified || user.passwordExpired) {
      return (
        <ul
          className="nav"
          ref={(c) => {
            this.nav = c;
          }}>

          <li className="nav-header"><span>Settings</span></li>
          <li className="li-small">
            <FlatButton containerElement={<Link to="/profile" />}>
              <i className="nav-icon nav-dot material-icons">settings</i>
              <span className="nav-text">Profile Settings</span>
            </FlatButton>
          </li>
        </ul>
      );
    }

    return (

      <ul
        className="nav"
        ref={(c) => {
          this.nav = c;
        }}>

        <li className="nav-header"><span>Navigation</span></li>


        <li className="li-small">
          <FlatButton containerElement={<Link to="/" />}>
            <i className="nav-icon material-icons">dashboard</i>
            <span className="nav-text">Dashboard</span>
          </FlatButton>
        </li>
        {Object.values(products).map((nav, i) => (
          <li key={i}>
            <FlatButton containerElement={<Link to={nav.path} />}>
              <img
                style={{
                  marginLeft: 2, height: 20, width: 20, marginRight: 20
                }}
                src={nav.imgLogo} />
              <span className="nav-text">{nav.name}</span>
            </FlatButton>
            {nav.nested

              && (
              <ul>
                {nav.nested.map((item, i) => (
                  <li key={i}>
                    <FlatButton
                      className='prepend-icon'
                      containerElement={(
                        <Link to={item.path} />
)}>
                      <span>{item.name}</span>
                    </FlatButton>
                  </li>
                ))}
              </ul>
              )
              }
          </li>
        ))}

        {/* TODO: Add billing */}
        {/* <li><FlatButton href="#/app/billing"><i className="nav-icon material-icons">cloud_download</i><span */}
        {/* className="nav-text">Billing</span></FlatButton> */}
        {/* </li> */}


        <li className="nav-divider" />
        <li className="nav-header"><span>Settings</span></li>
        <li className="li-small">
          <FlatButton containerElement={<Link to="/profile" />}>
            <i className="nav-icon nav-dot material-icons">settings</i>
            <span className="nav-text">Profile Settings</span>
          </FlatButton>
        </li>
      </ul>


    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.session.user,
});

const mapDispatchToProps = dispatch => ({
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SidebarContent);

import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from 'material-ui/Snackbar';
import {connect} from 'react-redux';
import {CLOSE_CONTACT_US_DIALOG, SNACK_BAR_REQUEST_CLOSE} from "../constants/ActionTypes";


//
const SnackbarComp = ({barState, message, action, handleRequestClose, handleActionClick}) => {

  if (action) {
    return <Snackbar
      open={barState}
      message={message}
      action={action}
      onActionClick={handleActionClick}
      onRequestClose={handleRequestClose}
    />
  }

  return (
    <Snackbar
      open={barState}
      message={message}
      autoHideDuration={4000}
      onRequestClose={handleRequestClose}
    />

  )
};


SnackbarComp.propTypes = {
  barState: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,

};


const mapStateToProps = (state, ownProps) => ({
    barState: state.ui.snackbar.open,
    message: state.ui.snackbar.message,
    action: state.ui.snackbar.action
});

const mapDispatchToProps = (dispatch) => ({
  handleRequestClose: (id) => {

    dispatch({type: SNACK_BAR_REQUEST_CLOSE})
  },
  handleActionClick: () => {
    dispatch({type: CLOSE_CONTACT_US_DIALOG});
    dispatch({type: SNACK_BAR_REQUEST_CLOSE});
  }

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SnackbarComp);




import {
  CHECK_INVITATION,
  CLEAR_SESSION,
  CLOSE_ACTIVATION_METHOD_DIALOG,
  CLOSE_CONTACT_US_DIALOG,
  CLOSE_INVITATION_CONFIRMATION_DIALOG,
  CLOSE_INVITE_DIALOG,
  CLOSE_SESSION_EXPIRY_DIALOG,
  CLOSE_USER_DELETE_DIALOG,
  CLOSE_USER_MANAGE_DIALOG,
  EMAIL_INVITATION_CONFIRMATION,
  EMAIL_VERIFICATION_CONFIRMED,
  INVITATION_CONFIRMATION,
  INVITATION_CONFIRMED,
  INVITATION_SENT,
  INVITATIONS_READY,
  OPEN_ACTIVATION_METHOD_DIALOG,
  OPEN_CONTACT_US_DIALOG,
  OPEN_INVITE_DIALOG,
  OPEN_SESSION_EXPIRY_DIALOG,
  OPEN_USER_DELETE_DIALOG,
  OPEN_USER_MANAGE_DIALOG,
  ORG_READY,
  SESSION_READY,
  UPDATE_IS_VERIFIED_SUCCESS,
  UPDATE_ORG_SUCCESS,
  UPDATE_PROFILE_BASIC_INFO
} from '../constants/ActionTypes';

const initialSettings = {
  user: {},
  token: {},
  invitations: [],
  displayInvitationConfirmationDialog: false,
  displayInvitationInputDialog: false,

  displayEmailInvitationDialog: false,
  displaySessionExpirationDialog: false,

  displayContactUsDialog: false,

  displayUserDeleteDialog: false,
  displayUserManageDialog: false,
  checkedUsers: [],

  displayActivationMethodDialog: false,

};

export const userIsOrgRoot = (user) => user && user.authorities && user.authorities.includes('ROLE_ROOT_ORG');


/**
 * Helper method to convert permissions into map with key=id, value=permission
 * Easier to access and check if they have particular permission
 * @param user
 */
const convertPermissionsToMap = (user) => {
  console.log("converting permissions to map for", user);

  if (Array.isArray(user.permissions)) {
    // convert permissions into map with key=id, value=permission
    let permissions = {};
    user.permissions.forEach(p => {
      permissions[p.id] = p;
    });
    user.permissions = permissions;
  }
};

export const session = (state = initialSettings, action) => {

  switch (action.type) {
    case SESSION_READY:
      convertPermissionsToMap(action.user);
      return {
        ...state,
        user: action.user,
        token: action.token,
      };

    case CLEAR_SESSION:
      return {
        ...state,
        user: {},
        token: {},
      };
    case OPEN_SESSION_EXPIRY_DIALOG:
      return {
        ...state,
        displaySessionExpirationDialog: true,

        // close other dialogs
        displayUserManageDialog: false,
        displayInvitationInputDialog: false,
        displayUserDeleteDialog: false,
        displayEmailInvitationDialog: false,
        displayInvitationConfirmationDialog: false,
        displayContactUsDialog: false
      };

    case CLOSE_SESSION_EXPIRY_DIALOG: {
      return {
        ...state,
        displaySessionExpirationDialog: false
      }
    }

    case OPEN_CONTACT_US_DIALOG: {
      return {
        ...state,
        displayContactUsDialog: true
      }
    }
    case CLOSE_CONTACT_US_DIALOG: {
      return {
        ...state,
        displayContactUsDialog: false
      }
    }

    case ORG_READY:
    case UPDATE_ORG_SUCCESS:

      return {
        ...state,
        org: action.payload,
      };

    case OPEN_INVITE_DIALOG:
      return {
        ...state,
        displayInvitationInputDialog: true
      };

    case CLOSE_INVITE_DIALOG:
      return {
        ...state,
        displayInvitationInputDialog: false
      };

    case OPEN_USER_DELETE_DIALOG:

      return {
        ...state,
        displayUserDeleteDialog: true,
      };

    case CLOSE_USER_DELETE_DIALOG:

      state.invitations.map(inv => {
        inv.checked = false;
      });

      return {
        ...state,
        displayUserDeleteDialog: false,
        checkedUsers: []
      };

    case OPEN_USER_MANAGE_DIALOG:

      return {
        ...state,
        displayUserManageDialog: true,
      };

    case CLOSE_USER_MANAGE_DIALOG:

      state.invitations.map(inv => {
        inv.checked = false;
      });

      return {
        ...state,
        displayUserManageDialog: false,
        checkedUsers: []
      };

    case OPEN_ACTIVATION_METHOD_DIALOG: {
      return {
        ...state,
        displayActivationMethodDialog: true
      }
    }

    case CLOSE_ACTIVATION_METHOD_DIALOG: {
      return {
        ...state,
        displayActivationMethodDialog: false
      }
    }

    case CHECK_INVITATION:

      // reverse the check
      state.invitations[action.index].checked = !state.invitations[action.index].checked;

      let users = [];

      state.invitations.map(inv => {
        if (inv.checked) {
          users.push({email: inv.targetEmail});
        }
      });

      return {
        ...state,
        checkedUsers: users
      };

    case INVITATION_SENT:
      return {
        ...state,
        displayInvitationInputDialog: false
      };

    case INVITATION_CONFIRMATION:
      return {
        ...state,
        displayInvitationConfirmationDialog: true,
        inviteOrgName: action.payload,
        invitationSession: action.invitation
      };
    case INVITATION_CONFIRMED:
      return {
        ...state,
        displayInvitationConfirmationDialog: false,
        invitationSession: null
      };
    case CLOSE_INVITATION_CONFIRMATION_DIALOG:
      return {
        ...state,
        displayEmailInvitationDialog: false,
        displayInvitationConfirmationDialog: false,
      };
    case INVITATIONS_READY:

      action.payload.map(inv => {
        inv.checked = false;
      });

      return {
        ...state,
        invitations: action.payload
      };
    case UPDATE_PROFILE_BASIC_INFO:
      convertPermissionsToMap(action.user)
      return {
        ...state,
        user: action.user
      };
    case EMAIL_INVITATION_CONFIRMATION:
      return {
        ...state,
        displayEmailInvitationDialog: true,
        invitationSession: action.invitation
      };
    case EMAIL_VERIFICATION_CONFIRMED:
      return {
        ...state,
        displayEmailInvitationDialog: false,
        invitationSession: null
      };
    case UPDATE_IS_VERIFIED_SUCCESS:
      convertPermissionsToMap(action.user);
      return {
        ...state,
        user: action.user
      };

    default:
      return state;
  }
};





import {
  OPEN_APP
} from '../constants/ActionTypes';

const initialSettings = {};

const app = (state = initialSettings, action) => {
  switch (action.type) {
    case OPEN_APP:
      return {
        ...state,
        layoutBoxed: action.isLayoutBoxed
      };
    default:
      return state;
  }
};

export default app;

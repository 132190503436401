
console.log("oauth2serverUrl:", OAUTH2_SERVER_URL);

export const Config = {
  // authHost: dev ? "http://localhost:9999" : "https://uat.login.gpayments.com",
  // licenseApiServerUrl: env === "prod" ? "https://lic.gpayments.com/api/v1" : ( env === "uat" ?  "https://uat.lic.gpayments.com/api/v1" : "https://dev.lic.gpayments.com/api/v1"),
  licenseApiServerUrl: LICENSE_API_SERVER_URL,
  // releaseApiServerUrl: env === "prod" ? "https://release.gpayments.com/api/v1" : ( env === "uat" ?  "https://uat.release.gpayments.com/api/v1" : "https://dev.release.gpayments.com/api/v1"),
  releaseApiServerUrl: RELEASE_API_SERVER_URL,

  downloadCloudFrontBaseUrl: DOWNLOAD_CLOUD_FRONT_BASE_URL,
  // host: dev ? "http://localhost:3000" : "https://uat.myaccount.3dsmpi.com", // must NOT end with "/"
  // authHost: "http://localhost:9999" ,
  // licenseApiServerUrl: "http://localhost:7070/api/v1",
  // releaseApiServerUrl: "http://localhost:8080/api/v1" ,
  // host: "http://localhost:3000", // must NOT end with "/"

  // authHost: env === "prod" ? "https://login.gpayments.com" : ( env === "uat" ?  "https://uat.login.gpayments.com" : "https://dev.login.gpayments.com"),
  authHost: OAUTH2_SERVER_URL,

  gpSiteBackendUrl: GP_SITE_BACKEND_URL
};


export const get = (session, url, include = "omit") => {
  return request('GET', session, url, null, include);
};

export const del = (session, url, data) => {
  return request('DELETE', session, url, data)
};

export const put = (session, url, data) => {
  return request('PUT', session, url, data)
};

export const post = (session, url, data = null, contentType = "application/json") => {
  return request('POST', session, url, data, "omit", contentType);
};

export const request = (method, session, url, data = null, include = "omit", contentType = "application/json") => {

  let body = data;

  if (contentType === "application/json" && body) {
    body = JSON.stringify(body);
  }

  return fetch(url, {
    headers: {
      Authorization: `Bearer ${session.token}`,
      'Content-Type': contentType
    },
    credentials: include, // we need include for set-cookie header
    method: method,
    body: body,
  });
};


import {
  NOTIFY_BAR_REQUEST_CLOSE,
  NOTIFY_BAR_REQUEST_OPEN,
  SNACK_BAR_REQUEST_CLOSE,
  SNACK_BAR_REQUEST_OPEN
} from '../constants/ActionTypes';

const initialSettings = {
  snackbar: {
    open: false,
    message: '',
    action: null
  },

  notifyBar: {
    open: false,
    message: '',
    action: null
  },
  lastUpdateTime: null,
};

const ui = (state = initialSettings, action) => {
  switch (action.type) {
    case SNACK_BAR_REQUEST_CLOSE:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          open: false,

        }
      };

    case SNACK_BAR_REQUEST_OPEN:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          open: true,
          message: action.message,
          action: action.action

        }
      };

    case NOTIFY_BAR_REQUEST_CLOSE:
      return {
        ...state,
        notifyBar: {
          ...state.notifyBar,
          open: false,

        }
      };

    case NOTIFY_BAR_REQUEST_OPEN:
      return {
        ...state,
        notifyBar: {
          ...state.notifyBar,
          open: true,
          message: action.message,
          action: action.action

        }
      };


    default:
      return state;
  }
};

export default ui;

import React, {Component} from 'react';
import {connect} from 'react-redux';
import classnames from 'classnames';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MainApp from '../routes/app/components/MainApp';
import Error404 from '../routes/404/components/404';
import FullscreenLoading from '../routes/loading/FullScreenLoading';
// = styles =
// 3rd
import 'styles/bootstrap.scss';
// custom
import 'styles/layout.scss';
import 'styles/theme.scss';
import 'styles/ui.scss';
import 'styles/app.scss';

import lightTheme from './themes/lightTheme';
import darkTheme from './themes/darkTheme';
import grayTheme from './themes/grayTheme';

import {Route, Router, Switch,} from 'react-router-dom';

import history from '../routes/history';
import {OPEN_SESSION_EXPIRY_DIALOG} from "../constants/ActionTypes";
import {doAuthRedirection} from "actions/auth";
import {createMuiTheme} from "@material-ui/core";
import ThemeProvider from "@material-ui/styles/ThemeProvider";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "rgb(0, 188, 212)",
    },
  },
});


class App extends Component {

  constructor(props) {
    super(props);

  }

  render() {
    const {layoutBoxed, navCollapsed, navBehind, fixedHeader, sidebarWidth, theme, session} = this.props;
    let materialUITheme;
    switch (theme) {
      case 'gray':
        materialUITheme = grayTheme;
        break;
      case 'dark':
        materialUITheme = darkTheme;
        break;
      default:
        materialUITheme = lightTheme;
    }

    return (
      <Router history={history}>
        <MuiThemeProvider muiTheme={getMuiTheme(materialUITheme)}>
          {/*<ThemeProvider theme={theme}>*/}
          <div id="app-inner">
            <div className="preloaderbar hide"><span className="bar"/></div>
            <div
              className={classnames('app-main full-height', {
                'fixed-header': fixedHeader,
                'nav-collapsed': navCollapsed,
                'nav-behind': navBehind,
                'layout-boxed': layoutBoxed,
                'theme-gray': theme === 'gray',
                'theme-dark': theme === 'dark',
                'sidebar-sm': sidebarWidth === 'small',
                'sidebar-lg': sidebarWidth === 'large'
              })
              }>

              {session.user.lastName ?

                <Switch>
                  <Route exact path='/404' component={Error404}/>

                  <Route component={MainApp}/>

                </Switch>
                : <FullscreenLoading/>
              }

            </div>
          </div>
          {/*</ThemeProvider>*/}
        </MuiThemeProvider>
      </Router>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  session: state.session,
  layoutBoxed: state.settings.layoutBoxed,
  navCollapsed: state.settings.navCollapsed,
  navBehind: state.settings.navBehind,
  fixedHeader: state.settings.fixedHeader,
  sidebarWidth: state.settings.sidebarWidth,
  theme: state.settings.theme,
});

const mapDispatchToProps = (dispatch) => ({
  sessionRefreshRequired: () => {
    dispatch({type: OPEN_SESSION_EXPIRY_DIALOG});
  },
  doAuthRedirection: () => dispatch(doAuthRedirection())

});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

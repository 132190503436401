export const DIRECTORY_SERVERS = {
  VISA: {
    title: 'VISA',
    testlab: true, // flag to indicate that its available on TestLabs
  },
  MASTERCARD: {
    title: 'MasterCard',
    testlab: true, // flag to indicate that its available on TestLabs
  },
  AMERICANEXPRESS: {
    title: 'American Express',
    testlab: true, // flag to indicate that its available on TestLabs
  },
  DINERS: {
    title: 'Diners Club International',
    testlab: true, // flag to indicate that its available on TestLabs
  },
  JCB: {
    title: 'JCB',
    testlab: true, // flag to indicate that its available on TestLabs
  },
  UNIONPAY: {
    title: 'UnionPay',
    testlab: false, // flag to indicate that its available on TestLabs
  },
};

export const DATA_POINTS = {
  AS: {
    value: 'AS',
    text: 'ActiveServer',
    title: 'ActiveServer Status',
    tooltip: 'The status of the ActiveServer, background health checker performs health check on ActiveServer SaaS instance. If there are any issues related to performing authentication, the issue will be shown as incidents.',
    severity: 1,
  },
  // DB: {
  //   value: 'DB',
  //   text: 'Database',
  //   title: 'ActiveServer Database status',
  //   tooltip: 'The status of the database server connected with ActiveServer'
  // },
  S3: {
    value: 'S3',
    text: 'AWS S3 Bucket',
    title: 'AWS S3 Bucket status',
    tooltip: 'The status of the AWS S3 connection with ActiveServer',
    severity: 2,
  },
  KMS: {
    value: 'KMS',
    text: 'AWS KMS',
    title: 'AWS KMS status',
    tooltip: 'The status of the AWS KMS connection with ActiveServer',
    severity: 1,
  },
  AUTH_API: {
    value: 'AUTH_API',
    text: 'Connection to Authentication API failed',
    title: 'ActiveServer Authentication API status',
    tooltip: 'The health checker performs mock Authentication API requests, and the callback URL accessibility.',
    severity: 1,
  },
  ADMIN_UI: {
    value: 'ADMIN_UI',
    text: 'Connection to Admin UI failed',
    title: 'ActiveServer Admin UI status',
    tooltip: 'The health checker accesses the admin UI login page. If accessible then the status is operational.',
    severity: 1,
  },
  // PROD_DS: {
  //   value: 'PROD_DS',
  //   text: 'Production Directory Server',
  // },
  // TEST_LABS_DS: {
  //   value: 'TEST_LABS_DS',
  //   text: 'TestLabs Directory Server',
  // },
  // PROD_DS_PORT: {
  //   value: 'PROD_DS_PORT',
  //   text: 'Production Directory Server port failed',
  //   title: 'Production Directory Server port',
  //   tooltip: 'Marked as an incident if connection from the uptime agent to ActiveServer production directory server port failed. ' +
  //     'If this occur RReq from the DS may not be received by ActiveServer successfully.'
  // },
  // TEST_LABS_DS_PORT: {
  //   value: 'TEST_LABS_DS_PORT',
  //   text: 'TestLabs Directory Server port failed',
  //   title: 'TestLabs Directory Server port',
  //   tooltip: 'Marked as an incident if connection from the uptime agent to ActiveServer GPayments TestLabs directory server port failed.' +
  //     'If this occur RReq from the DS may not be received by ActiveServer successfully.'
  // },
  // OTHER: {
  //   value: 'OTHER',
  //   text: 'Other type of incidents',
  //   title: 'Other incidents',
  //   tooltip: 'The status of other incidents'
  // }
};

export const INCIDENT_VISIBILITIES = {
  MINOR: {
    text: 'Minor',
    value: 'MINOR',
  },
  MAJOR: {
    text: 'Major',
    value: 'MAJOR',
  },
  PUBLISHED: {
    text: 'Publish',
    value: 'PUBLISHED',
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from 'material-ui/Snackbar';
import {connect} from 'react-redux';


//
const NotificationComp = ({barState, message, action, handleRequestClose, handleActionTouch}) => (

  <Snackbar
    style={{top: 80, bottom: 'auto'}}
    bodyStyle={{backgroundColor: '#EF5350'}}
    contentStyle={{backgroundColor: '#EF5350'}}
    open={barState}
    message={<span style={{
      display: 'flex',
      alignItems: 'center'
    }}>
      <i style={{marginRight: 10}} className="material-icons">error</i>{message}
        </span>}
    action={action}
    onRequestClose={handleRequestClose}
  />
);


NotificationComp.propTypes = {
  barState: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,

};


const mapStateToProps = (state, ownProps) => ({
    barState: state.ui.notifyBar.open,
    message: state.ui.notifyBar.message,
    action: state.ui.notifyBar.action
  }
);

const mapDispatchToProps = (dispatch) => ({
  handleRequestClose: () => console.log("clickAway")
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationComp);




import React from 'react';
import {connect} from 'react-redux';
import classnames from 'classnames';
import {Link} from 'react-router-dom'
import APPCONFIG from 'constants/Config';
import NavRightList from './NavRightList';
import NavLeftList from "components/Header/NavLeftList";
import NotificationBar from "components/NotificationBar";
import {NOTIFY_BAR_REQUEST_OPEN} from "../../constants/ActionTypes";
import SessionExpiryDialog from "components/SessionExpiryDialog";

class Header extends React.Component {
  componentDidMount() {
    const sidebarToggler = this.sidebarBtn;
    const $sidebarToggler = $(sidebarToggler);
    const $body = $('#body');

    $sidebarToggler.on('click', (e) => {
      // _sidebar.scss, _page-container.scss
      $body.toggleClass('sidebar-mobile-open');
    });

    const {user} = this.props;

    if (!user.verified) {
      this.props.notifyBarOpen('Please verify your email to access MyAccount features');
    } else if (user.passwordExpired) {
      this.props.notifyBarOpen('Please update your password to access MyAccount features');

    }
  }

  render() {
    const {isFixedHeader, colorOption} = this.props;

    return (
      <section className="app-header">
        <div
          className={classnames('app-header-inner', {
            'bg-color-light': ['11', '12', '13', '14', '15', '16', '21'].indexOf(colorOption) >= 0,
            'bg-color-dark': colorOption === '31',
            'bg-color-primary': ['22', '32'].indexOf(colorOption) >= 0,
            'bg-color-success': ['23', '33'].indexOf(colorOption) >= 0,
            'bg-color-info': ['24', '34'].indexOf(colorOption) >= 0,
            'bg-color-warning': ['25', '35'].indexOf(colorOption) >= 0,
            'bg-color-danger': ['26', '36'].indexOf(colorOption) >= 0
          })}
        >
          <div className="hidden-lg-up float-left">
            <a href="javascript:;" className="md-button header-icon toggle-sidebar-btn"
               ref={(c) => {
                 this.sidebarBtn = c;
               }}>
              <i className="material-icons">menu</i>
            </a>
          </div>


          <div className="brand hidden-md-down">
            <h2><Link to="/">{APPCONFIG.brand}</Link></h2>
          </div>

          {this.props.displaySessionExpirationDialog &&
          <SessionExpiryDialog/>
          }

          <div className="top-nav-left hidden-md-down">
            <NavLeftList/>
          </div>


          <NotificationBar/>

          <div className="top-nav-right">

            <NavRightList/>
          </div>
        </div>
      </section>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  notifyBarOpen: (message) => dispatch({type: NOTIFY_BAR_REQUEST_OPEN, message: message})
});

const mapStateToProps = (state, ownProps) => ({
  colorOption: state.settings.colorOption,
  isFixedHeader: state.settings.isFixedHeader,
  user: state.session.user,
  displaySessionExpirationDialog: state.session.displaySessionExpirationDialog

});

module.exports = connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);


import React from 'react';
import Header from '../../../components/Header';
import Sidenav from '../../../components/Sidenav';
import Footer from '../../../components/Footer';

import {Redirect, Route, Switch,} from 'react-router-dom';

import modules from '../../modules';
import SnackBar from '../../../components/SnackBar';

// wrap <Route> and use this everywhere instead, then when
// sub routes are added to any route it'll work
const RouteWithSubRoutes = (route) => (
  <Route exact path={route.path} render={props => (
    // pass the sub-routes down to keep nesting
    <route.component {...props} routes={route.routes}/>
  )}/>
)


const MainApp = (props) => {
  return (
    <div className="main-app-container">

      <Sidenav/>

      <section id="page-container" className="app-page-container">
        <Header/>

        <div className="app-content-wrapper">
          <div className="app-content">

            <div className="full-height">
              <Switch>

                {modules.map((route, i) => (
                  <Route exact {...route} key={i}/>
                ))}
                {/*{routes.map((route, i) => (*/}
                {/*<RouteWithSubRoutes key={i} {...route} />*/}
                {/*))}*/}

                <Redirect to='/404'/>
              </Switch>

            </div>
          </div>
          <SnackBar/>

          <Footer/>
        </div>
      </section>
      {/*Enable if you want to customize theme*/}
      {/*<Customizer/>*/}
    </div>)
};

export default MainApp;

import React from 'react';
import APPCONFIG from 'constants/Config';

class Footer extends React.Component {
  render() {
    return (
      <section className="app-footer">
        <div className="container-fluid text-center">
          <span>
            <span>Copyright © {APPCONFIG.year} <a className="brand" target="_blank" href={APPCONFIG.productLink}>{APPCONFIG.company}</a> </span>
          </span>
          {/*<span className="float-right">*/}
          {/*<span>Version {APPCONFIG.version}<i className="material-icons">favorite_border</i></span>*/}
          {/*</span>*/}
        </div>
      </section>
    );
  }
}

module.exports = Footer;

import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';
import settings from './settings';
import apps from './app';
import releases from './releases';
import {session} from './session';
import ui from './ui';

import {reducer as formReducer} from 'redux-form';
import {
  SDK_REGISTERED,
  SENT_ENQUIRY,
  SERVER_REGISTERED,
  SUBSCRIBE_SUCCESSFUL,
  UPDATE_PASSWORD_SUCCESS
} from "../constants/ActionTypes";
import license from "./license";

const reducers = {
  routing: routerReducer,
  settings,
  apps,
  releases,
  session,
  ui,
  license,
  form: formReducer.plugin({
    updatePasswordForm: (state, action) => { // this is to auto clear update password form after submit
      switch (action.type) {
        case UPDATE_PASSWORD_SUCCESS:
          return undefined;       // <--- blow away form data
        default:
          return state;
      }
    },
    regServerForm: (state, action) => { // this is to auto clear server reg form after submit
      switch (action.type) {
        case SERVER_REGISTERED:
          return undefined;       // <--- blow away form data
        default:
          return state;
      }
    }, regSDKForm: (state, action) => { // this is to auto clear sdk reg form after submit
      switch (action.type) {
        case SDK_REGISTERED:
          return undefined;       // <--- blow away form data
        default:
          return state;
      }
    }, contactForm: (state, action) => {
      switch (action.type) {
        case SENT_ENQUIRY:
          return undefined;       // <--- blow away form data
        default:
          return state;
      }
    }, subscriptionsForm: (state, action) => {
      switch (action.type) {
        case SUBSCRIBE_SUCCESSFUL:
          return undefined;       // <--- blow away form data
        default:
          return state;
      }

    }
  })
};

export default combineReducers(reducers);

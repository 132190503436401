export const AA = 'aa';
export const AA_SAAS = 'aa-saas';
export const AA2 = 'aa2';
export const AA2_SAAS = 'aa2-saas';
export const AME = 'ame';
export const AM_SAAS = 'am-saas';
export const SDK = 'sdk';
export const SDK_IOS = 'sdk-ios';
export const SDK_ANDROID = 'sdk-android';
export const AS = 'as';
export const AS_SAAS = 'as-saas';

const logoImagePath = '/assets/images/logos/';

export const products = {
  ame: {
    code: AME,
    name:
      'ActiveMerchant',
    imgDashboard:
      `${logoImagePath}AM_logo_less_height.png`,
    imgNoBorder: `${logoImagePath}AM_logo_no_border.png`,
    imgLogo: `${logoImagePath}AM_logo_only.png`,
    imgStyle: {height: '40%', width: '40%'},
    subHeading:
      '3D Secure 1 Merchant Plug-In (MPI)',
    description:
    'Designed for large merchants, payment service providers and acquirers to provide 3D Secure 1 card-not-present authentication '
    + 'for the Verified by Visa, Mastercard SecureCode, JCB J/Secure, American Express SafeKey and Diners Club International ProtectBuy authentication standards.',
    description2:
      'ActiveMerchant enterprise runs in a standalone web server offering both a redirection and API implementation model, is scalable across multiple servers and utilises most relational databases for secure data storage.',
    path:
      '/releases/ame/latest',
    available: true
  },
  aa: {
    code: AA,
    name:
      'ActiveAccess',
    imgDashboard:
      `${logoImagePath}AA_logo_less_height.png`,
    imgNoBorder: `${logoImagePath}AA_logo_no_border.png`,
    imgLogo: `${logoImagePath}AA_logo_only.png`,
    imgStyle: {height: '35%', width: '35%'},
    subHeading:
      '3D Secure 1 and 2 Access Control Server (ACS)',
    description:
      'ActiveAccess incorporates a high-performance and scalable authentication server, a role-based administration management server with a centralised, web-based interface, and enrolment and registration servers for management of cardholder accounts. The system supports single and multiple issuers.',
    description2:
      'The ActiveAccess multi-factor authentication platform is a fully featured, bank-grade application designed for card issuers, enterprises, government and third party application service providers.',
    path: '/docs/aa',
    available: true,
    nested: [
      {
        name: 'Documentation',
        permissionRequired: false,
        path: '/docs/aa'
      },
      {
        name: 'Download',
        permissionRequired: true,
        path: '/releases/aa/latest'
      }
    ]

  },
  as: {
    code: AS,
    name:
      'ActiveServer',
    imgDashboard:
      `${logoImagePath}AS_logo_less_height.png`,
    imgNoBorder: `${logoImagePath}AS_logo_no_border.png`,
    imgLogo: `${logoImagePath}AS_logo_only.png`,
    subHeading:
      '3D Secure 2 3DS Server (3DSS)',
    description:
      'Updated merchant side component for 3DS2, which has been expanded to cover the new mobile, frictionless (risk-based) and non-payment authentication process introduced in 3DS2 while still providing the core merchant liability shift 3DS offers.',
    description2:
      'ActiveServer leverages the latest improvements from the EMVCo 3DS2 protocol (such as risk-based, multi-factor and non-payment authentication, biometrics) to give merchants greater security and flexibility during eCommerce transactions.',
    description3: "3DS2 has introduced a brand new merchant side\n" +
      "component, 3DS Server. GPayments ActiveServer is designed to comply with the latest protocol updates\n" +
      "to provide merchants with the latest improvements such as native support for mobile application and\n" +
      "frictionless authentication flow.",
    path: '/myapps/3ds-server',
    available: true,
    nested: [
      {
        name: 'My Instances',
        permissionRequired: true,
        path: '/myapps/3ds-server'
      },
      {
        name: 'SaaS',
        permissionRequired: true,
        path: '/saas/uptime'
      },
      {
        name: 'Documentation',
        permissionRequired: false,
        path: '/docs/as'
      },
      {
        name: 'Download',
        permissionRequired: true,
        path: '/releases/as/latest'
      }
    ]

  },
  sdk: {
    code: SDK,
    name:
      'ActiveSDK',
    imgDashboard:
      `${logoImagePath}SDK_logo_less_height.png`,
    imgNoBorder: `${logoImagePath}SDK_logo_no_border.png`,
    imgLogo: `${logoImagePath}SDK_logo_only.png`,
    subHeading:
      '3D Secure 2 3DS Software Development Kit (SDK)',
    description:
      'Newly introduced component for 3DS2, Android and iOS mobile SDK\'s to allow merchants to offer 3DS2 authentication for their customers natively via merchant apps.',
    description2:
      'ActiveSDK allows merchants to support the rise of eCommerce by natively supporting 3DS2 in mobile apps, offering a seamless and secure cardholder experience while facilitating the rich data exchange required for risk-based authentication.',
    description3: "3DS2 brings native mobile support to merchant applications with the " +
      "              introduction of the 3DS SDK. GPayments' ActiveSDK supports both the Android and iOS ecosystems and\n" +
      "              enables\n" +
      "              rich data collection from card holder devices to make frictionless authentication possible.",
    path:
      '/myapps/sdk',
    available:
      false
  },
};

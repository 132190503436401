import React from "react";
import {CLOSE_CONTACT_US_DIALOG} from "../../constants/ActionTypes";
import {useDispatch, useSelector} from "react-redux";
import ContactForm from "../../routes/app/routes/releases/ContactForm";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

const ContactDialog = (props) => {

  const {displayContactUsDialog} = useSelector(state => state.session);

  const dispatch = useDispatch();

  const closeDialog = () => {
    dispatch({type: CLOSE_CONTACT_US_DIALOG});
  };


  return (

    <Dialog maxWidth={"md"}
            className="contact-support"
      // actions={actions}
      // modal={false}

            open={displayContactUsDialog}
            onClose={closeDialog}
    >
      <DialogContent>
        <h4><strong>Support Enquiry</strong></h4>
        <span>Need a hand? Feel free to send an enquiry to us.</span>
        <ContactForm enquiryTypes={true}/>
      </DialogContent>
    </Dialog>
  )
}

export default ContactDialog;

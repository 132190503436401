import React from 'react';
import {CircularProgress} from "@material-ui/core";

const FullscreenLoading = () => (
  <div className="page-err">
    <div className="err-container text-center">
      <div className="err">
        <h3>GP MyAccount Loading...</h3>
      </div>
      <CircularProgress size={75} thickness={3}/>
    </div>
  </div>
);

export default FullscreenLoading;

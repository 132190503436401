import React from 'react';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import IconButton from 'material-ui/IconButton/IconButton';
import History from '../../routes/history';
import {connect} from 'react-redux';
import {logout} from "actions/auth";

const ImgIconButtonStyle = {
  width: '60px',
  height: '60px'
};

const listItemStyle = {
  paddingLeft: '50px' // 36 + 16, algin with sub list
};

class NavRightList extends React.Component {

  handleChange = (event, value) => {
    History.push(value);
  }

  render() {
    const {user, org, logout} = this.props;
    return (
      <ul className="list-unstyled float-right">


        {org &&
        <li className="list-inline-item">

          {/*<DropDownMenu*/}
          {/*value={1}*/}
          {/*onChange={this.handleChange}*/}
          {/*style={styles.customWidth}*/}
          {/*autoWidth={true}*/}
          {/*>*/}
          {/*<MenuItem value={1} primaryText={org.name}/>*/}
            {/*<Divider/>*/}
            {/*<MenuItem value={2} primaryText="Change My Organization"/>*/}
            {/*<MenuItem value={4} primaryText="Send Invitation Email"/>*/}
          {/*</DropDownMenu>*/}


          {/*<div className="d-inline-block">G</div>*/}


        </li>
        }

        <li style={{marginRight: '10px'}}>
          <IconMenu
            iconButtonElement={<IconButton style={ImgIconButtonStyle}>
              <img src="/assets/images/profile-unknown.jpg" alt="" className="rounded-circle img30_30"/></IconButton>}
            onChange={this.handleChange}
            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            targetOrigin={{horizontal: 'right', vertical: 'top'}}
            menuStyle={{minWidth: '150px'}}
          >
            <MenuItem
              primaryText={`Hello ${user.firstName} ${user.lastName}`}
              disabled={true}
              style={{fontSize: '14px', lineHeight: '50px'}}
              innerDivStyle={listItemStyle}
              leftIcon={<i className="material-icons">person_outline</i>}
            />

            {(user.verified && !user.passwordExpired) &&
            <MenuItem
              value="/"
              primaryText="Dashboard"
              style={{fontSize: '14px', lineHeight: '48px'}}
              innerDivStyle={listItemStyle}
              leftIcon={<i className="material-icons">home</i>}
            />
            }
            <MenuItem
              value="/profile"
              primaryText="Profile & Settings"
              innerDivStyle={listItemStyle}
              style={{fontSize: '14px', lineHeight: '48px'}}
              leftIcon={<i className="material-icons">settings</i>}
            />
            <MenuItem
              primaryText="Log Out"
              innerDivStyle={listItemStyle}
              onClick={logout}
              style={{fontSize: '14px', lineHeight: '48px'}}
              leftIcon={<i className="material-icons">forward</i>}
            />

          </IconMenu>
        </li>
      </ul>
    );
  }
}


const mapStateToProps = (state) => ({
  user: state.session.user,
  org: state.session.org
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavRightList);



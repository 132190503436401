import {Config, get} from './http'
import queryString from 'query-string';
import {
  CLEAR_SESSION,
  OPEN_SESSION_EXPIRY_DIALOG,
  ORG_READY,
  SESSION_READY,
  SET_REFRESH_TOKEN_EXPIRY
} from "../constants/ActionTypes";
import History from '../routes/history';
import {SESSION_GRACE_DIALOG_TIME} from "../constants/session";


export const STARTING_AUTH = 'STARTING_AUTH';


export const doAuthRedirection = () => {

  const state = Math.random().toString(36).substring(7);


  console.log('generate state token: ' + state);
  console.log("current location: ", window.location.href)

  //
  // sessionStorage.setItem('sessionState', state);

  const redirectUrl = `${Config.authHost}/oauth/authorize?client_id=gp-trusted-client&redirect_uri=${encodeURIComponent(window.location.href)}&response_type=token&scope=trust&state=${state}`;

  console.log('about to redirect to:', redirectUrl);

  window.location.href = redirectUrl;

};


const startingAuth = () => {
  return {type: STARTING_AUTH}
};

const sessionReady = (token, user) => {


  console.log("token: " + token);

  return (dispatch) => {
    // if user is not enabled force logout
    if (!user.enabled) {
      console.log("user is not enabled!");
      dispatch(logout());
    }

    dispatch({type: SESSION_READY, token, user});

    fetchOrganization(token, dispatch);


  }


};

/**
 * XXX: Should this method be inside different action, maybe create new reducer `dashboard`
 * Fetches details about the organisation. Currently, only used to fetch the tracker url.
 * @returns {Function}
 */
export const fetchOrgDetails = () => {
  return (dispatch, getState) => {

    get({token: getState().session.token}, Config.authHost + "/api/v1/user/org/details")
      .then(function (response) {
        if (!response.ok) {

          throw Error(response.status, response.statusText);
        }
        return response.json();
      }).then((body) => {

      console.log('fetch org returns body', body);

      dispatch({type: ORG_READY, payload: body.data});


    }).catch((error) => {

      console.log("fetch org error", error)


    })
  }
};


const fetchOrganization = (token, dispatch) => {
  get({token}, Config.authHost + "/api/v1/user/org")
    .then(function (response) {
      if (!response.ok) {

        throw Error(response.status, response.statusText);
      }
      return response.json();
    }).then((body) => {

    console.log('fetch org returns body', body);

    dispatch({type: ORG_READY, payload: body.data});


  }).catch((error) => {

    console.log("fetch org error", error)


  })
};

export const fetchUser = (token, dispatch, updateUrl = true) => {
  get({token}, Config.authHost + "/api/v1/user")
    .then(function (response) {
      if (!response.ok) {

        throw Error(response.status, response.statusText);
      }
      return response.json();
    }).then((body) => {

    const user = body;
    console.log('fetch user returns body', user);
    // console.log('cookies', document.cookie);

    dispatch(sessionReady(token, user))


    if (updateUrl) {


      //to remove the hash which contains access key
      let pathWithoutHash = window.location.pathname + window.location.search;

      // redirect to profile page if not verified or password is expired
      if (!user.verified || user.passwordExpired) {
        pathWithoutHash = `/profile/${window.location.search}`;
      }

      console.log('url after fetch user: ', pathWithoutHash);

      //redirect to the path, replace the current path to remove the access token in url.
      History.replace(pathWithoutHash);
    }

  }).catch((error) => {

    console.log("error", error)
    const status = error.response ? error.response.status : "unknown, maybe local error.";

    console.log("user info error: ", status);


  })
};

export const tryAuth = (session, url) => {

  console.log("session", session);
  console.log("current config: ", Config);
  console.log("current env", process.env.NODE_ENV);
  console.log("init auth redirection url", url);

  //to return function for the middleware to handle
  return (dispatch) => {

    if (session.loggedIn && session.user) {
      dispatch(sessionReady(session.token, session.user));
      //should check the session valid or not.

      return;
    }

    dispatch(startingAuth());


    const parsed = queryString.parse(url);


    if (parsed && parsed.access_token) {

      setTimeout(() => dispatch({type: OPEN_SESSION_EXPIRY_DIALOG}), (parsed.expires_in - SESSION_GRACE_DIALOG_TIME) * 1000);
      //we have the token now.
      fetchUser(parsed.access_token, dispatch)

    } else {

      doAuthRedirection();

    }

  }
};


export const logout = () => {

  return (dispatch) => {

    dispatch({type: CLEAR_SESSION});

    //TODO: How to logout user from oauth2 server?
    //We tried iframe but not possible if x-frame-options are configured
    //Looks like the best option is to redirect user to the auto logout page on the
    //oauth2 server.
    window.location.href = `${Config.authHost}/logout`;

  }

};

import {Config, get, post} from './http';
import {
  ALL_VERSIONS_READY,
  FAILED_TO_FETCH_UPTIME,
  INCIDENTS_READY,
  SENDING_ENQUIRY,
  SENT_ENQUIRY,
  SET_VERSION_TO_DISPLAY,
  SNACK_BAR_REQUEST_OPEN,
  START_FETCHING,
  UPTIME_STATS_READY,
  VERSION_FILE_INFO_READY,
  VERSIONS_READY
} from '../constants/ActionTypes';
import moment from 'moment';
import {openSnackBar} from 'actions/ui';


export const versionsReady = (product, versions) => {
  return {
    type: VERSIONS_READY,
    product,
    versions,

  };
};

export const setVersionToDisplay = (product, version) => {

  return (dispatch, getState) => {


    if (getState().releases.fetched) {

      console.log('version', version);
      //if the versions are already fetched

      dispatch({
        type: SET_VERSION_TO_DISPLAY,
        product,
        version
      });

      //load version file info
      dispatch(loadVersionFileInfo(product, version));

      //load version file info of other documentation
      dispatch(loadVersionFileInfo(product, 'other'));
    } else {
      //versions are not ready, could be the case that the site is opened by version number url.

      dispatch(refreshVersions(product, version));


    }


  };
};

export const loadVersionFileInfo = (product, version) => {


  return (dispatch, getState) => {

    get(getState().session, Config.releaseApiServerUrl + '/' + product + '/' + version)
      .then(function (response) {
        if (!response.ok) {

          throw Error(response.status, response.statusText);
        }
        return response.json();
      })
      .then((body) => {

        console.log('fetch version info', body);

        dispatch({
          type: VERSION_FILE_INFO_READY,
          product,
          version,
          data: body.data
        });


      })
      .catch((error) => {

        console.log('error', error);


      });

  };

};

export const fetchVersions = (session, product) => {
  return get(session, Config.releaseApiServerUrl + '/' + product + '/versions')
    .then((response) => {

      if (!response.ok) {
        throw Error(response.status, response.statusText);
      }

      return response.json();

    })
    .then((body) => {
      console.log('fetch version', body);

      return body.data;

    })
    .catch((error) => {
      console.log('error', error);
    });
};


/**
 * Fetch all product versions which the user has permission on and wait for all products to be fetched.
 * @returns {Function}
 */
export const fetchAllProductVersions = () => {

  return (dispatch, getState) => {

    const {user} = getState().session;
    const {session} = getState();

    dispatch({type: START_FETCHING});

    Promise.all(
      Object.values(user.permissions)
        .map(permission => {

          return Promise.resolve(fetchVersions(session, permission.code))
            .then(data => ({
              version: data,
              product: permission.code
            }));
        })
    )
      .then(data => {
        dispatch({
          type: ALL_VERSIONS_READY,
          data
        });
      });
  };
};

export const refreshVersions = (product, version, loadInfo = true) => {

  return (dispatch, getState) => {

    dispatch({type: START_FETCHING});

    Promise.resolve(fetchVersions(getState().session, product))

      .then(data => {
        dispatch(versionsReady(product, data));

        // only load the version info if loadInfo is true
        if (loadInfo) {
          //load version info first
          if (data && data.length > 0) {

            const {versionToDisplay} = getState().releases;

            if (versionToDisplay[product]) {
              dispatch(loadVersionFileInfo(product, versionToDisplay[product].version));
            }
            //load version file info of supporting documentation
            dispatch(loadVersionFileInfo(product, 'other'));
          }

          //if to load specified version, set that version and load it.
          if (version) dispatch(setVersionToDisplay(product, version));
        }
      });
  };
};

/**
 * Get signed cookie from oauth2 server with set-cookie header
 * @param product
 * @returns {Function}
 */
export const getSignedCookie = (product) => {
  return (dispatch, getState) => {
    get(getState().session, Config.releaseApiServerUrl + `/dl/${product}/cookie`, 'include')
      .then(function (response) {
        if (!response.ok) {

          throw Error(response.status, response.statusText);
        }
        return response.json();
      })
      .then((body) => {
        console.log('cookie fetched successfully');
      })
      .catch((error) => {

        console.log('error', error);


      });
  };
};

/**
 * Log what has been downloaded
 */
export const logDownload = (id) => {
  return (dispatch, getState) => {
    post(getState().session, Config.releaseApiServerUrl + `/log/${id}`)
      .then(response => {
        console.log(`Log download for ${id}`);
      })
      .then(error => {
        console.log('error', error);

      });
  };
};

/**
 *
 */
export const submitEnquiry = (values, setError) => {
  return (dispatch, getState) => {

    console.log('form submitted', values);

    const {user} = getState().session;

    const data = new URLSearchParams();
    if (values.message) {
      data.append('message', values.message);
    } else if (values.enquiry) {
      data.append('message', values.enquiry);
    }
    data.append('name', user.firstName);
    data.append('email', user.email);
    data.append('enquiry', values.type);
    data.append('url', window.location.href);

    dispatch({type: SENDING_ENQUIRY}); //put a circular loading screen

    return post(getState().session, `${Config.gpSiteBackendUrl}/messaging/enquiry`, data, 'application/x-www-form-urlencoded')
      .then(response => {
        dispatch({type: SENT_ENQUIRY});

        if (!response.ok) {
          throw Error(response.status, response.statusText);
        }

        return response.json();
      })
      .then(body => {
        console.log(body.data);

        dispatch({
          type: SNACK_BAR_REQUEST_OPEN,
          message: 'Thank you! We will respond to your registered email address',
          action: 'Done'
        });

      })
      .catch((error) => {
        dispatch({type: SENT_ENQUIRY});
        setError('api', 'error', error.message);
        console.error('error', error);
      });
  };
};


export const getIncidents = (occurredFrom, occurredTo) => {

  return (dispatch, getState) => {

    let url = `${Config.releaseApiServerUrl}/incidents?occurredFrom=${moment.utc(occurredFrom)
      .format('YYYY-MM-DDTHH:mm:ss')}&occurredTo=${moment.utc(occurredTo)
      .format('YYYY-MM-DDTHH:mm:ss')}`;


    get(getState().session, url)
      .then(response => {
        if (!response.ok) {
          throw Error(response.status, response.statusText);
        }

        return response.json();
      })
      .then(body => {
        console.log(body.data);

        dispatch({
          type: INCIDENTS_READY,
          data: body.data
        });

      })
      .catch((error) => {
        console.error('error', error);
        dispatch(openSnackBar('Failed to fetch ActiveServer service status!'));

        dispatch({
          type: FAILED_TO_FETCH_UPTIME,
          ready: false
        });
      });


  };
};

export const getUptimeStats = (occurredFrom, occurredTo) => {

  return (dispatch, getState) => {

    let url = `${Config.releaseApiServerUrl}/uptime?occurredFrom=${moment.utc(occurredFrom)
      .format('YYYY-MM-DDTHH:mm:ss')}&occurredTo=${moment.utc(occurredTo)
      .format('YYYY-MM-DDTHH:mm:ss')}`;


    get(getState().session, url)
      .then(response => {
        if (!response.ok) {
          throw Error(response.status, response.statusText);
        }

        return response.json();
      })
      .then(body => {
        console.log(body.data);

        dispatch({
          type: UPTIME_STATS_READY,
          data: body.data,
          occurredFrom: moment.utc(occurredFrom),
          occurredTo: moment.utc(occurredTo),
        });

      })
      .catch((error) => {
        console.error('error', error);
        dispatch(openSnackBar('Failed to fetch uptime statistics!'));
        dispatch({
          type: FAILED_TO_FETCH_UPTIME,
          ready: false
        });
      });


  };
};


